.bazis-list {
    --list-style: none;
    --font-size: 13px;
    --font-weight: 400;
    --line-height: 20px;

    // types
    &_h {
        > .bazis-item {
            --margin-end: var(--bazis-margin-3x);
        }
    }

    &_short-info {
        > .bazis-item {
            --padding-start: var(--bazis-padding-3x);
            --padding-end: var(--bazis-padding-3x);
            --margin-end: 0;
            --font-size: 12px;
            --color: var(--bazis-text-color-secondary);
        }
    }

    &_vline {
        --padding-start: var(--bazis-padding-5x);
        --border-color: var(--bazis-border-color);

        .bazis-checkbox__label,
        .bazis-radio__label {
            margin-right: 0;
        }

        &.bazis-list_h > .bazis-item {
            --margin-end: 0;
        }

        &_mini {
            @extend .bazis-list_vline;
            --padding-start: var(--bazis-padding-3x);
        }
    }

    &_select {
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --background-hover: var(--bazis-background-dark);
        --background-selected: var(--bazis-color-action);
        --color-selected: var(--bazis-color-action-contrast);

        --max-height: 208px;
        --background-list: var(--bazis-color-white);
    }

    &_control-dd {
        --max-height: 208px;
        --border-radius-list: var(--bazis-border-radius);
        --border-width-list: 1px;
        --border-style-list: solid;
        --border-color-list: var(--bazis-background-medium);
        --padding-top-list: var(--bazis-padding-3x);
        --padding-bottom-list: var(--bazis-padding-3x);
        --margin-top-list: 3px;
        --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);

        &-wrapper {
            --height: 36px;
            --padding-bottom: var(--bazis-padding-2x);

            .bazis-control & {
                --padding-bottom: 0;
            }
        }

        .bazis-dropdown & {
            bazis-button.bazis-item {
                --background: transparent;
                --background-hover: var(--bazis-background-dark);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
                --padding-top: var(--bazis-padding-2x);
                --padding-bottom: var(--bazis-padding-2x);
                text-align: left;

                &::part(inner) {
                    --padding-start: 0;
                    --padding-end: 0;
                    justify-content: flex-start;
                }

                &:active:hover {
                    &::part(inner) {
                        background: var(--background-selected);
                        color: var(--color-selected);
                    }
                }
            }
        }

        .bazis-block_on-map & {
            --box-shadow: 0;
            --border-width-list: 0;
        }
    }

    &_gallery {
        > .bazis-item {
            --margin-end: var(--bazis-margin-1x);
        }

        .bazis-figure {
            --width: 271px;

            &_medium {
                --width: 604px;
            }
        }
    }

    &_between {
        > .bazis-item {
            --margin-bottom: var(--bazis-margin-1x);
        }

        &_dot-leaders {
            > .bazis-item {
                --margin-top: 0;
                --margin-bottom: var(--bazis-margin-5x);

                &:last-child {
                    --margin-bottom: 0;
                }
            }
            --color-dot: var(--bazis-text-color-secondary);
        }
    }

    &_underline,
    &_overline {
        --margin-bottom: 0;
        --border-radius: 0;
    }

    &_step {
        --padding-start-list: var(--bazis-padding-5x);
        --padding-end-list: var(--bazis-padding-5x);
        --padding-top-list: var(--bazis-padding-6x);
        --padding-bottom-list: var(--bazis-padding-6x);
        --border-width-list: 1px;
        --border-style-list: solid;
        --border-color-list: var(--bazis-border-color);
        --border-radius-list: var(--bazis-border-radius);

        --margin-bottom: var(--bazis-margin-4x);

        .bazis-item_parent {
            & > bazis-step {
                --font-weight: 500;
            }

            &.bazis-item--invalid > bazis-step {
                --color: var(--color-muted);
            }
        }

        .bazis-item--empty {
            bazis-step {
                --sign-background: var(--sign-background-muted);
                --color: var(--color-muted);
                --sign-color: var(--sign-color-muted);
            }

            &.bazis-item--required bazis-step {
                --color: var(--bazis-text-color);
            }
        }

        .bazis-item--invalid:not(.bazis-item--empty) bazis-step {
            --sign-background: var(--bazis-color-danger);
            --color: var(--bazis-color-danger);
        }

        > .bazis-item:last-child {
            --margin-bottom: 0;
        }

        .bazis-list .bazis-item {
            --margin-bottom: var(--bazis-margin-2x);
        }
    }

    &_checkable {
        --padding-start-list: var(--bazis-padding-5x);
        --padding-end-list: var(--bazis-padding-5x);
        --padding-top-list: var(--bazis-padding-6x);
        --padding-bottom-list: var(--bazis-padding-6x);
        --border-width-list: 1px;
        --border-style-list: solid;
        --border-color-list: var(--bazis-border-color);
        --border-radius-list: var(--bazis-border-radius);

        --margin-bottom: var(--bazis-margin-4x);

        .bazis-item > bazis-step {
            cursor: pointer;
        }

        .bazis-item_parent {
            & > bazis-step {
                --font-weight: 500;

                &::part(sign) {
                    visibility: hidden;
                }

                &::part(label) {
                    border-bottom: none;
                }
            }

            &.bazis-item--invalid > bazis-step {
                --color: var(--color-muted);
            }
        }

        .bazis-item--empty {
            bazis-step {
                --sign-background: var(--sign-background-muted);
                --color: var(--color-muted);
                --sign-color: var(--sign-color-muted);
            }

            &.bazis-item--required bazis-step {
                --color: var(--bazis-text-color);
            }
        }

        .bazis-item--invalid:not(.bazis-item--empty) bazis-step {
            --sign-background: var(--bazis-color-danger);
            --color: var(--bazis-color-danger);
        }

        > .bazis-item:last-child {
            --margin-bottom: 0;

            .bazis-item:last-child {
                --margin-bottom: 0;
            }
        }

        .bazis-list .bazis-item {
            --margin-bottom: var(--bazis-margin-2x);
        }
    }

    &_history {
        > .bazis-item {
            --margin-bottom: var(--bazis-margin-7x);
            --padding-start: var(--bazis-padding-8x);

            .bazis-browse-default {
                width: 156px;
                margin-right: var(--bazis-margin-8x);

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &_e-sign {
        --margin-bottom: 40px;
        --margin-top-dl: var(--bazis-margin-4x);

        textarea {
            border-style: dashed;
            border-color: var(--bazis-placeholder-color);
            border-radius: var(--bazis-border-radius);
        }

        bazis-link {
            letter-spacing: 0.25px;
        }
    }
}
