/* Core CSS required for components to work properly */
@import 'core/core';

/* Basic CSS for apps built */
@import 'core/normalize';
@import 'core/structure';
@import 'core/scroll';
@import "core/typography";

/* Optional CSS utils that can be commented out */
@import 'utils/display-utils';
@import 'utils/flex-utils';
@import 'utils/float-elements';
@import 'utils/font';
@import 'utils/line-elements';
@import 'utils/padding';
@import 'utils/margin';
@import 'utils/text-alignment';
@import 'utils/text-decoration';
@import 'utils/text-transformation';
@import 'utils/other';

/* Mixins */
@import 'mixins/flex';
@import 'mixins/media-query';
@import 'mixins/gradients';
@import 'mixins/text-ellipsis';
@import 'mixins/props';

/* Base classes and Common components of alphabet */
@import "base/main.scss";
@import "components/common/main.scss";

/* Web Components, reserved classes */
@import "components/web/main";

/* Form Components */
@import "components/form/main.scss";

/* Table Components */
@import 'components/table/table.base';

/* Chat Components */
@import 'components/chat/chat';

/* MAP */
@import 'components/map/map';

/* Chart components */
@import 'components/chart/main';

/* Modules */
@import 'modules/main';

/* Pages */
@import 'pages/page';
@import 'pages/dummy-page';
@import 'pages/list-page';
