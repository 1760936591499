.bazis-chats {
    --height-header: 65px;
    --width-left-col: 317px;
    --width-photo: 240px;

    display: flex;
    min-height: 518px;
    height: calc(100vh - 236px);
    border-radius: var(--bazis-border-radius);

    &__search {
        height: var(--height-header);
        padding: 14px var(--bazis-padding-4x);
        border-bottom: 1px solid var(--bazis-border-color);
        border-radius: var(--bazis-border-radius) 0 0 0;

        .bazis-control {
            --margin-bottom: 0;
        }
    }
}

.bazis-chat-list-wrapper {
    height: 100%;
    width: var(--width-left-col);
    flex: 0 0 var(--width-left-col);
    border-right: 1px solid var(--bazis-border-color);
}

.bazis-list_chat {
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --padding-top: var(--bazis-padding-3x);
    --padding-bottom: var(--bazis-padding-3x);
    --border-width: 0 0 1px;
    --border-color: var(--bazis-border-color);
    --border-style: solid;
    --border-radius: 0;

    overflow: auto;
    height: calc(100% - var(--height-header));

    .bazis-item {
        --background-hover: var(--bazis-background-medium);
        --background-selected: var(--bazis-color-primary);
        --color-selected: rgba(var(--bazis-color-primary-contrast-rgb), 1);

        cursor: pointer;

        bazis-toolbar ~ * {
            margin-top: var(--bazis-margin-1x);
            line-height: 16px;
        }

        bazis-badge {
            --margin-start: 0;
            --margin-end: 0
            --font-size-medium: 10px;
            min-width: 16px;
        }

        .bazis-chat__text *:not(.bazis-chat__emoticon, a) {
            display: contents;
            &::after {
                content: '\00a0';
            }
        }

        a {
            display: contents;
            pointer-events: none;
            text-decoration: none;
        }

        &--selected,
        &--selected:hover,
        &:active {

            .bazis-chat__text,
            .bazis-chat__date {
                color: rgba(var(--bazis-color-primary-contrast-rgb), .8);
            }

            a {
                // display: inline-block;
                color: rgba(var(--bazis-color-primary-contrast-rgb), .8);
                // text-decoration: underline;
            }
        }
    }
}

bazis-chat,
bazis-chat-not-selected {
    width: calc(100% - var(--width-left-col));
}

bazis-chat-not-selected,
.bazis-chat__empty-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    color: var(--bazis-text-color-secondary);
}

.bazis-chat-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.bazis-chat__header {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: 128px minmax(calc(100% - 128px * 2 - 20px * 2), 1fr) 128px; // 128 - под кнопки слева и справа
    justify-content: space-between;
    align-items: center;
    gap: var(--bazis-margin-5x);
    width: 100%;
    // position: relative;
    height: var(--height-header);
    padding: var(--bazis-padding-3x) var(--bazis-padding-6x);
    border-bottom: 1px solid var(--bazis-border-color);
    border-radius: 0 var(--bazis-border-radius) 0 0;

    &__participant {
        width: 100%;
        margin-top: var(--bazis-margin-1x);
        text-align: center;
        line-height: 16px;

        bazis-link:not(.bazis-dropdown__trigger) {
            display: inline-block;
            overflow: hidden;
            max-width: calc(100% - 75px);
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
        }
    }

    // bazis-toolbar {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     height: 100%;
    //     width: 100%;
    // }

    .bazis-dropdown {
        --top: 27px;
        --width: 260px;
        --right: auto;
        --left: 0;
        display: inline-block;
        text-align: left;
    }
}

.bazis-chat__main {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(100% - var(--height-header));
    max-height: calc(100% - var(--height-header));

    .bazis-control {
        --margin-bottom: 0;
    }
}

.bazis-chat__content {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: var(--bazis-padding-5x) var(--bazis-padding-2x) var(--bazis-padding-5x) var(--bazis-padding-4x);

    &__scroll {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        overflow: auto;
        padding-right: var(--bazis-padding-2x);
    }
}

.bazis-chat__footer {
    padding: var(--bazis-padding-4x) var(--bazis-padding-6x);
    border-top: 1px solid var(--bazis-border-color);
}

.bazis-chat__to-bottom {
    position: absolute;
    right: var(--bazis-margin-5x);
    bottom: var(--bazis-margin-5x);
}

.bazis-chat__message {
    display: flex;
    gap: var(--bazis-margin-4x);
    position: relative;
    margin: var(--bazis-margin-3x) 0;
    padding: var(--bazis-padding-2x);
    border-radius: var(--bazis-border-radius);

    &__body {
        width: calc(
            100% - (32px + 16px)
        ); // 100% минус колонка с аватаром, минус gap

        bazis-toolbar ~ *:not(.bazis-chat__reactions),
        cite > * {
            display: block;
            margin-top: var(--bazis-margin-2x);
        }

        .bazis-file-inline {
            .bazis-list .bazis-item:last-child & {
                margin-bottom: 0;
            }
        }
    }

    &__cite {
        border-left: 2px solid var(--bazis-text-color);
        padding-left: calc(var(--bazis-padding-4x) - 2px);
        font-style: normal;
    }

    &__note {
        font-size: 11px;
        line-height: 12px;
        color: var(--bazis-text-color-secondary);
    }

    bazis-message:first-child & .bazis-dropdown:not(.bazis-dropdown_bottom) {
        --top: auto;
        --bottom: 28px;
    }

    .bazis-btn_clear.bazis-btn_small.bazis-btn_has-icon-only {
        // --background: var(--bazis-background);
        --background-hover: var(--bazis-color-info-tint);
    }

    .bazis-avatar {
        letter-spacing: 0.25px;
    }

    &__reactions {
        position: relative;
        margin-bottom: var(--bazis-margin-4x);

        .bazis-btn_xs {
            position: absolute;
            top: var(--bazis-padding-3x);
            right: var(--bazis-padding-4x);
        }
    }

    &:hover {
        background: var(--bazis-background-medium);
    }

    &--highlighting {
        animation-name: highlighting;
        animation-duration: 1000ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }
}

@keyframes highlighting {
    from {
        background-color: transparent;
    }
    50% {
        background-color: var(--bazis-background-accent);
    }
    to {
        background-color: transparent;
    }
  }

.bazis-chat__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.bazis-chat__date {
    font-size: 12px;
    line-height: 16px;
    color: var(--bazis-text-color-secondary);
}

.bazis-chat__text {
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;

    .bazis-list_chat & {
        font-size: 13px;
        line-height: 16px;
        color:  var(--bazis-text-color-secondary);
    }

    img {

        &.bazis-chat__emoticon {
            display: inline-block;
            vertical-align: middle;
            width: 16px; height: 16px;
        }
    }
}

.bazis-chat__group-photo {
    --height-row: calc(400px / 12);
    display: grid;
    max-width: 364px;
    gap: var(--bazis-margin-2x);
    /* Будет создано 6 колонок */
    grid-template-columns: repeat(6, 1fr); // 54px
    /* Будет создано 6 рядов */
    grid-template-rows: repeat(6, var(--height-row));
    grid-auto-rows: var(--height-row);

    & + & {
        margin-top: var(--bazis-margin-2x);
    }
}

.bazis-chat__photo {
    // border-radius: var(--bazis-border-radius);
    // border: 1px solid var(--bazis-border-color);
    // background: var(--bazis-background);
    // width: var(--width-photo);
    width: 100%;
    height: 100%;
    text-decoration: none;

    grid-row: span 4;
    grid-column: span 3;

    &-1 {
        &:only-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4), &:nth-last-child(5){
            grid-row: span 6;
            grid-column: span 6;
        }

        &:nth-last-child(6) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-2 {
        &:last-child{
            grid-row: span 6;
            grid-column: span 6;
        }

        &:nth-last-child(2) {
            grid-row: span 6;
            grid-column: span 3;
        }
        &:nth-last-child(3) {
            grid-row: span 2;
            grid-column: span 2;
        }
        &:nth-last-child(4) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(5) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-3 {
        &:last-child{
            grid-row: span 6;
            grid-column: span 3;
        }

        &:nth-last-child(2) {
            grid-row: span 2;
            grid-column: span 2;
        }
        &:nth-last-child(3) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(4) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-4 {
        &:last-child {
            grid-row: span 2;
            grid-column: span 2;
        }

        &:nth-last-child(2) {
            grid-row: span 3;
            grid-column: span 3;
        }
        &:nth-last-child(3) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-5 {
        &:last-child {
            grid-row: span 3;
            grid-column: span 3;
        }

        &:nth-last-child(2) {
            grid-row: span 4;
            grid-column: span 3;
        }
    }
    &-6 {
        grid-row: span 4;
        grid-column: span 3;
    }

    .bazis-figure {
        // --width: calc(var(--width-photo) - 2px);
        // --height: calc(var(--width-photo) - 1px);
        --width: 100%;
        --height: 100%;
        --border-width: 1px;
        --border-radius: var(--bazis-border-radius);
        margin: 0;

        img {
            --border-radius: 0;
        }

        bazis-button {
            position: absolute;
            top: var(--bazis-margin-1x);
            right: var(--bazis-margin-1x);
        }
    }

    .bazis-figcaption {
        display: flex;
        padding: var(--bazis-padding-4x);
        gap: var(--bazis-margin-3x);
        align-items: center;
        text-decoration: none;
        color: var(--bazis-text-color);

        .bh-ellipsis {
            max-width: 154px;
        }
    }

    &__size {
        display: block;
        // padding-top: var(--bazis-padding-1x);
        font-size: 11px;
        line-height: 12px;
        color: var(--bazis-text-color-secondary);
    }

    bazis-badge {
        --height: 36px;
        --padding-start: var(--bazis-padding-1x);
        --padding-end: var(--bazis-padding-1x);
        --padding-top: 3px;
        --padding-bottom: 1px;
        --margin-start: 0;
        --margin-end: 0;
        --font-size: 12px;
        border-radius: 2px;
        float: left;
        width: 40px;
        line-height: 32px;
        text-transform: uppercase;

        &:last-of-type {
            display: none;
            align-items: center;
            border-radius: var(--bazis-border-radius);
        }
    }

    &:hover {
        bazis-badge {
            &:first-of-type {
                display: none;
            }
            &:last-of-type {
                display: block;
            }
        }
    }
}

.bazis-chat__reply {
    display: flex;
    gap: var(--bazis-margin-4x);
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--bazis-margin-4x);

    .bazis-chat__title {
        margin-bottom: var(--bazis-margin-1x);
    }

    .bazis-chat__text {
        font-size: 13px;
        line-height: 16px;
    }

    .bazis-btn_solid {
        --background: transparent;
        --color: var(--bazis-text-color-secondary);
        --background-hover: var(--bazis-border-color);
        --color-link-hover: var(--color);
    }
}

.bazis-chat__control {
    position: relative;
    display: flex;
    gap: var(--bazis-margin-6x);

    .bazis-modal & {
        padding-top: var(--bazis-padding-4x);
        padding-bottom: var(--bazis-padding-4x);
        border-top: 1px solid var(--bazis-border-color);
        margin-top: var(--bazis-margin-6x);
        margin-left: calc(var(--padding-start-content) * -1);
        margin-right: calc(var(--padding-end-content) * -1);
        padding-left: var(--padding-start-content);
        padding-right: var(--padding-end-content);
    }

    &__emojis {
        bazis-emojis {
            display: none;
        }

        &:hover  bazis-emojis {
            display: block;
        }
    }

    .bazis-control {
        --margin-bottom: 0;
    }
}

.bazis-chat__field {
    &-group {
        display: flex;
        width: 100%;
        gap: var(--bazis-margin-4x);
    }

    &-wrapper {
        display: flex;
        gap: var(--bazis-margin-3x);
        overflow-y: auto;
        overflow-x: hidden;

        width: 100%;
        min-height: 36px;
        height: auto;
        max-height: 108px; // 36 * 3
        border: 1px solid var(--bazis-border-color);
        border-radius: var(--bazis-border-radius);
        padding-left: var(--bazis-padding-3x);

        .bazis-control {
            --control-width: 36px;
            flex-shrink: 0;

            bazis-button {
                --height: 34px;
                --border-width: 0;
                width: var(--control-width);

                bazis-icon {
                    color: var(--bazis-placeholder-color);
                }
            }
        }

        &--focused {
            border-color: var(--bazis-color-primary);
        }
    }

    width: 100%;
    min-height: 18px;
    height: auto;
    max-height: 100%;
    margin: var(--bazis-margin-2x) 0;
    word-break: break-word;
    line-height: 18px;

    &:focus-visible {
        border: none;
        outline: none;
    }

    &:empty::before {
        content: attr(data-placeholder);
        display: block; /* Важное требование для комфортной работы в Firefox */
        pointer-events: none; /* Исключаем взаимодействие с подсказкой */
        line-height: 18px;
        color: var(--bazis-placeholder-color);
    }

}

.bazis-chat__field {
    img {
        display: block;
        width: calc(var(--width-photo) - 2px);
        height: calc(var(--width-photo) - 1px);
        // border-width: 0 0 1px 0;
        // border-style: solid;
        // border-color: var(--bazis-border-color);
        // border-radius: var(--bazis-border-radius) var(--bazis-border-radius) 0 0;

        &.bazis-chat__emoticon {
            display: inline-block;
            vertical-align: middle;
            width: 20px; height: 20px;
            // border: none;
            // border-radius: 0;
        }
    }

}

.bazis-chat__files {
    display: flex;
    flex-direction: column;
    gap: var(--bazis-margin-3x); //4x

    bazis-file-inline {
        width: 392px;
    }

    &__item {
        display: flex;
        width: 100%;
        gap: var(--bazis-margin-4x);
        justify-content: space-between;
        align-items: center;

        > &:first-child {
            width: calc(100% - 1px);
        }

        bazis-button {
            flex-shrink: 0;
        }
    }

    bazis-sign-status {
        --size-sign: 36px;

        .bazis-loader_inline {
            --height: 28px;
            --min-width: 24px;
            --spinner-size: 24px;
            --spinner-color: var(--bazis-color-white);
            --spinner-background: var(--bazis-color-base);
            padding: 2px;
            margin: 4px;
            width: 28px;
            border-radius: 50%;

            .bazis-loader__spinner {
                margin: auto;
                padding: 4px;
            }
        }
    }
}

.bazis-chat__reactions {
    display: flex;
    flex-wrap: wrap;
    gap: var(--bazis-margin-2x);
    margin-top: var(--bazis-margin-2x);

    &__item {
        display: flex;
        align-items: center;
        gap: var(--bazis-margin-2x);
        border-radius: 20px;
        padding: var(--bazis-padding-1x) var(--bazis-padding-1x) var(--bazis-padding-1x) var(--bazis-padding-2x);
        background: var(--bazis-background-dark);

        img {
            width: 16px;
            height: 16px;
            margin: var(--bazis-padding-1x);
        }

        .bazis-avatar {
            --width: 40px;
            --height: 40px;
            font-size: 8px;
        }

        &__users {
            display: flex;
            flex-wrap: wrap;
            gap: var(--bazis-margin-1x);
        }
    }
}

.bazis-chat__emoticons {
    position: absolute;
    z-index: 150;
    right: 48px;
    top: 40px;

    width: 342px; // 218
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);
    background: var(--bazis-background);
    padding: var(--bazis-padding-3x) var(--bazis-padding-4x);

    > div {
        display: flex;
        flex-wrap: wrap;
        gap: var(--bazis-margin-4x);
    }

    img {
        display: block;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    &::before {
        content: '';
        display: block;
        height: 15px;
        position: absolute;
        left: 0;
        right: 0;
        top: -15px;
    }

    .bazis-chat__control & {
        top: auto;
        right: 0;
        bottom: 45px;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05) inset, 0px 4px 24px -10px rgba(0, 0, 0, 0.10);

        &::before {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            height: 15px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -15px;
        }

        .bazis-modal & {
            // top: -31px;
            right: 24px;
        }
    }

    .bazis-context-menu & {
        position: static;

        > div {
            max-height: 144px;
            overflow-y: auto;
            overflow-x: hidden;
            margin-right: calc(var(--bazis-margin-2x) * -1);
        }
    }
}

.bazis-context-menu bazis-context-menu-message {
    bazis-button {
        --height-xs: 20px;
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;

        &.bazis-btn_xs bazis-icon[slot=icon-only] {
            font-size: 20px;
        }
    }
}

.bazis-chat__media {
    position: absolute;
    top: var(--height-header);
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background: var(--bazis-color-white);
    z-index: 150;
    display: flex;
    flex-direction: column;

    &__header {
        flex: 0 0 48px;
        padding-top: var(--bazis-padding-3x);
        border-bottom: 1px solid var(--bazis-border-color);

        bazis-tabs {
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
            margin-top: 0
        }
    }

    &__content {
        flex: 1 1 auto;
        min-height: calc(100% - 48px);
        overflow: auto;
        padding: var(--bazis-padding-8x) var(--bazis-padding-6x);
    }

    &__group {
        margin-top: var(--bazis-margin-4x);
        margin-bottom: var(--bazis-margin-6x);
    }

    .bazis-chat__group-photo {
        --height-row: 116px;
        max-width: 100%;
        max-width: 631px;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: var(--height-row);

        & + & {
            margin-top: var(--bazis-margin-2x);
        }
    }

    .bazis-chat__photo {
        grid-row: span 1 !important;
        grid-column: span 3;

        &-1 {
            &:only-child, &:nth-last-child(2) {
                grid-column: span 6;
            }
            &:nth-last-child(3) {
                grid-column: span 4;
            }
            &:nth-last-child(4) {
                grid-column: span 3;
            }
        }
        &-2 {
            &:last-child {
                grid-column: span 6;
            }
            &:nth-last-child(2) {
                grid-column: span 4;
            }
            &:nth-last-child(3) {
                grid-column: span 3;
            }
        }
        &-3 {
            &:last-child{
                grid-column: span 4;
            }

            &:nth-last-child(2) {
                grid-column: span 3;
            }
        }
        &-4 {
            &:last-child {
                grid-column: span 3;
            }
        }
    }

    .bazis-list .bazis-item {
        &:first-child {
            --padding-top: 0;
        }
        &:last-child .bazis-file-inline {
            margin-bottom: 0;
        }
    }
}

.bazis-chat__search {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    padding: calc(var(--bazis-padding-3x) + 2px) var(--bazis-padding-6x);
    background: var(--bazis-color-white);
    border-bottom: 1px solid var(--bazis-border-color);

    bazis-toolbar::part(start),
    bazis-input-search {
        display: block;
        width: 100%;
    }

    .bazis-list_control-dd-wrapper,
    bazis-input-search > .bazis-control {
        position: static;
    }

    .bazis-control .bazis-list_control-dd {
        --margin-top-list: 0;
        --padding-end-list: var(--bazis-padding-2x);
        --padding-bottom-list: var(--bazis-padding-2x);
        --border-width-list: 0 0 1px 0;
        --background-list: var(--bazis-color-white);

        --position-top: 65px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: var(--bazis-padding-1x);
        --padding-bottom: var(--bazis-padding-1x);
        --border-width: 0 0 1px 0;
        --border-color: var(--bazis-border-color);
        --border-style: solid;
        --background: transparent;
        --background-hover: transparent;

        .bazis-item:last-child {
            --border-width: 0;
        }
    }

    bazis-infinite-list.bazis-list_control-dd .bazis-infinite-scroll-content {
        border-width: 0 0 1px 0;
        border-radius: 0;
        padding-top: var(--bazis-padding-2x);
        padding-bottom: var(--bazis-padding-2x);
        padding-left: var(--bazis-padding-6x);
        padding-right: var(--bazis-padding-4x);
    }

    .bazis-chat__message {
        margin: 0;
    }
}

bazis-chat-send-message {
    & > .bh-flex-column {
        height: 100%;
    }
}

.bazis-chat__modal-content {
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-right: var(--bazis-padding-6x);
}
