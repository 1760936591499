$leaflet-container-bg:							#ddd !default;
$leaflet-container-highlight:					transparent !default;
$leaflet-container-highlight-link:				rgba(51, 181, 229, 0.4) !default;
$leaflet-container-link-disabled-bg:			#f4f4f4 !default;
$leaflet-container-link-disabled-color:			#bbb !default;
$leaflet-container-pic-bg-gradient-start:		#65799b !default;
$leaflet-container-pic-bg-gradient-end:			#5e2563 !default;
$leaflet-container-pic-color:					white !default;
$leaflet-control-layers-expanded-color:			#333 !default;
$leaflet-control-layers-expanded-bg:			#fff !default;
$leaflet-control-layers-separator-border:		#ddd !default;
$leaflet-control-attribution-bg:				#fff !default;
$leaflet-control-attribution-rgba-bg:			rgba(255, 255, 255, 0.7) !default;
$leaflet-control-attribution-color:				#333 !default;
$leaflet-control-scale-line-border:				#777 !default;
$leaflet-control-scale-line-bg:					#fff !default;
$leaflet-control-scale-line-rgba-bg:			rgba(255, 255, 255, 0.5) !default;
/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
}
.leaflet-container {
	overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	user-select: none;
	  -webkit-user-drag: none;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
	width: 1600px;
	height: 1600px;
	-webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
	max-width: none !important;
	max-height: none !important;
	border-radius: 0 !important;
	object-fit: none !important;
}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y;
	touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	/* Fallback for FF which doesn't support pinch-zoom */
	touch-action: none;
	touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none;
	touch-action: none;
}
.leaflet-container {
	-webkit-tap-highlight-color: $leaflet-container-highlight;
}
.leaflet-container a {
	-webkit-tap-highlight-color: $leaflet-container-highlight-link;
}
.leaflet-tile {
	filter: inherit;
	visibility: hidden;
}
.leaflet-tile-loaded {
	visibility: inherit;
}
.leaflet-zoom-box {
	width: 0;
	height: 0;
	z-index: 80;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
}

.leaflet-pane         { z-index: 40; }

.leaflet-pane.leaflet-tile-pane    { z-index: 20; }
.leaflet-pane.leaflet-editor_base-pane { z-index: 30;}
.leaflet-pane.leaflet-overlay-pane { z-index: 40; }
.leaflet-pane.leaflet-shadow-pane  { z-index: 50; }
.leaflet-pane.leaflet-marker-pane  { z-index: 60; }
.leaflet-pane.leaflet-tooltip-pane   { z-index: 65; }
.leaflet-pane.leaflet-popup-pane   { z-index: 7000; }

.leaflet-map-pane canvas { z-index: 10; }
.leaflet-map-pane svg    { z-index: 20; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
}
.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
}


/* control positioning */
.leaflet-control-container {
	> div {
		display: flex;
		// @include flex-row();
		align-items: flex-start;
	}
}

.leaflet-control {
	position: relative;
	// z-index: 80;
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
	position: absolute;
	pointer-events: none;
}
.leaflet-top {
	z-index: 100;
	top: 0;
}
.leaflet-right {
	right: 0;
}
.leaflet-bottom {
	z-index: 0;
	bottom: 0;
}
.leaflet-left {
	left: 0;
}
.leaflet-control {
	float: left;
	clear: both;
}
.leaflet-right .leaflet-control {
	float: right;
}
.leaflet-top .leaflet-control {
	margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
	margin-left: 10px;
}
.leaflet-right .leaflet-control {
	margin-right: 10px;
}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
	will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
}
.leaflet-zoom-animated {
	transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
	will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
	transition: transform 0.25s cubic-bezier(0,0,0.25,1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
}


/* cursors */

.leaflet-interactive {
	cursor: pointer;
}
.leaflet-grab {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
}
.leaflet-map-pane,
.leaflet-canvas-pane { 
	will-change: transform;
	// transform: translate3d(0px, 0px, 0px);
	width: 100%;
	height: 100%;
}
// .leaflet-canvas-pane canvas {
// 	width: 100% !important;
// 	height: 100% !important;
// }

/* visual tweaks */

.leaflet-container {
	// background: $leaflet-container-bg;
	outline: 0;
}


/* general toolbar styles */
.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: $leaflet-container-link-disabled-bg;
	color: $leaflet-container-link-disabled-color;
}

// .leaflet-touch .leaflet-bar a {
// 	width: 30px;
// 	height: 30px;
// 	line-height: 30px;
// }
// .leaflet-touch .leaflet-bar a:first-child {
// 	border-top-left-radius: 2px;
// 	border-top-right-radius: 2px;
// }
// .leaflet-touch .leaflet-bar a:last-child {
// 	border-bottom-left-radius: 2px;
// 	border-bottom-right-radius: 2px;
// }

/* layers control */

// .leaflet-control-layers {
// 	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
// 	background: #fff;
// 	border-radius: 5px;
// }
// .leaflet-control-layers-toggle {
// 	background-image: url(images/layers.png);
// 	width: 36px;
// 	height: 36px;
// }
// .leaflet-retina .leaflet-control-layers-toggle {
// 	background-image: url(images/layers-2x.png);
// 	background-size: 26px 26px;
// }
// .leaflet-touch .leaflet-control-layers-toggle {
// 	width: 44px;
// 	height: 44px;
// }
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: $leaflet-control-layers-expanded-color;
	background: $leaflet-control-layers-expanded-bg;
}
.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
}
.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
}
.leaflet-control-layers label {
	display: block;
}
.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid $leaflet-control-layers-separator-border;
	margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
	background-image: url("#{$path}/images/marker-icon.png");
}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: $leaflet-control-attribution-bg;
	background: $leaflet-control-attribution-rgba-bg;
	margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: $leaflet-control-attribution-color;
}
.leaflet-control-attribution a {
	text-decoration: none;
}
.leaflet-control-attribution a:hover {
	text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
}
.leaflet-control-scale-line {
	border: 2px solid $leaflet-control-scale-line-border;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;

	background: $leaflet-control-scale-line-bg;
	background: $leaflet-control-scale-line-rgba-bg;
}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid $leaflet-control-scale-line-border;
	border-bottom: none;
	margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid $leaflet-control-scale-line-border;
}

// .leaflet-touch .leaflet-control-attribution,
// .leaflet-touch .leaflet-control-layers,
// .leaflet-touch .leaflet-bar {
// 	box-shadow: none;
// }

/////////////////////////
.leaflet-control-layers-list {
    z-index: 110;
}

.leaflet-interactive {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-container {
    &__inner {
		display: flex;

        &__pic {
            width: 44px;
            min-height: 44px;
            float: left;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            background: linear-gradient(135deg, $leaflet-container-pic-bg-gradient-start 0%,$leaflet-container-pic-bg-gradient-end 100%);
            i {
                font-size: 32px;
                color: $leaflet-container-pic-color;
            }
        }

        &__content {
           padding: 10px var(--bazis-padding-5x) 10px 15px;
        }
    }
}